<!--
 * @Autor: oops.liu
 顶部导航栏适配垫子
-->
<template>
  <div class="nav-bar" :style="{ background: bg }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    bg: {
      type: String,
      default: "#fff",
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
  watch: {},
};
</script>

<style scoped lang="scss">
.nav-bar {
  box-sizing: border-box;
  padding-top: 30px;
}
</style>
