/*
 * @Autor: oops.liu
 *存储公共常量
 */
// app返回事件
const HANDLE_BACK = "handleBack";
// app调用初始化事件
const LOAD_SELLER_INFO = "loadSellerInfo";
// 调用app商品详情
const JUMP_DETAIL = "jumpDetail";
// 联系我们
const HANDLE_CONTACT = "handleContact";
// 跳转店铺
const JUMP_SELLER = "jumpSeller";
// 店铺消息按钮跳转
const HANDLE_MESSAGE = "handleMessage";
// 店铺分享按钮点击
const HANDLE_SHARE_SELLER = "handleShareSeller";
// 获取token
const GET_TOKEN = "getToken";
// 保存token的字段
const TOKENKEY = "Authorization";
// app端，设置js端token
const JSGETTOKEN = "jsGetToken";
// 跳转证照信息
const JUMP_VERIFY = "jumpVerify";

export { HANDLE_BACK, LOAD_SELLER_INFO, JUMP_DETAIL, HANDLE_CONTACT, JUMP_SELLER, HANDLE_MESSAGE, HANDLE_SHARE_SELLER, GET_TOKEN, TOKENKEY, JSGETTOKEN ,JUMP_VERIFY};
