<!--
 * @Autor: oops.liu
-->
<template>
  <div class="seller_box">
    <div class="navbar_box">
      <div class="top_box">
        <div class="top_bg">
          <img :src="info.backImg" alt="" />
          <i class="bg_color"></i>
        </div>
        <div class="top_content">
          <navbarbox bg="transparent">
            <van-nav-bar
              title="店铺详情 "
              left-arrow
              :border="false"
              @click-left="toback"
              @click-right="onClickRight"
            >
              <template #right>
                <div class="nav_dot">
                  <img src="~@/assets/img/share.png" alt="" />
                </div>
              </template>
            </van-nav-bar>
          </navbarbox>
        </div>
      </div>
    </div>
    <div class="seller_content">
      <scroller>
        <div class="seller_info">
          <div class="sller_bar_box common_box">
            <div class="seller_bar flex_sb" @click="seller">
              <div class="logo">
                <img :src="info.imgUrl" alt="" />
              </div>
              <div class="seller_name_box">
                <p class="seller_name">{{ info.name }}</p>
                <p class="seller_hint">{{ info.stars | starsFilter }}人关注</p>
              </div>
              <div class="seller_btn">
                <van-button class="follow" v-if="info.focusStatus=='0'" @click.stop="follow"
                  >关注店铺</van-button
                >
                <van-button class="follow" v-if="info.focusStatus=='1'" @click.stop="cancle"
                  >已关注</van-button
                >
              </div>
            </div>
          </div>
          <!-- <div class="evaluate_box common_box">
            <div class="item flex_sb">
              <p class="label">用户评价</p>
              <div class="value green">
                <p class="count">9.35分</p>
                <p class="evaluate">低</p>
              </div>
            </div>
            <div class="item pd_item flex_sb">
              <p class="label">物流履约</p>
              <div class="value red">
                <p class="count">9.35分</p>
                <p class="evaluate">低</p>
              </div>
            </div>
            <div class="item flex_sb">
              <p class="label">售后服务</p>
              <div class="value red">
                <p class="count">9.35分</p>
                <p class="evaluate">低</p>
              </div>
            </div>
          </div> -->
          <div class="picture_box common_box">
            <div class="cell flex_sb ui-1px-b" @click="to_verify">
              <div class="title">证照信息</div>
              <div class="icon">
                <img src="~@/assets/img/photo.png" alt="" />
              </div>
            </div>
            <div class="cell flex_sb" @click="showQrCode">
              <div class="title">店铺二维码</div>
              <div class="icon">
                <img src="~@/assets/img/qr.png" alt="" />
              </div>
            </div>
          </div>
          <div class="seller_detail common_box">
            <div class="item flex_sb ui-1px-b">
              <p class="label">店铺简介</p>
              <p class="value">{{ info.detail }}</p>
            </div>
            <div class="item flex_sb ui-1px-b">
              <p class="label">所在地区</p>
              <p class="value">{{ info.area }}</p>
            </div>
            <div class="item flex_sb">
              <p class="label">开店时间</p>
              <p class="value">{{ info.startTime }}</p>
            </div>
          </div>
          <div class="brand common_box flex_sb">
            <p class="label">销售品牌</p>
            <p class="value">{{ info.brand }}</p>
          </div>
          <div class="hint_box common_box" @click="jumpGoods">
            <div class="hint">去看看全部商品 <i class="next"></i></div>
          </div>
        </div>
      </scroller>
    </div>
    <div class="qr_code_box">
      <van-dialog
        v-model="show"
        :close-on-click-overlay="true"
        :show-confirm-button="false"
        width="300"
      >
        <p class="seller_name">{{ info.name }}</p>
        <p class="hint">邀请好友来扫一扫分享店铺给TA</p>
        <div class="qr_code">
          <img :src="info.qrCode" alt="" />
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import native from "@/utils/native.js";
import {
  HANDLE_BACK,
  HANDLE_SHARE_SELLER,
  JUMP_VERIFY,
} from "@/mixin/mixin_file.js";
import vueQr from "vue-qr";
import navbarbox from "@/components/common/navbarbox.vue";

export default {
  components: { vueQr, navbarbox },
  props: {},
  data() {
    return {
      bg: require("@/assets/img/shop/bg.png"),
      value: "",
      show: false,
      url: "http://192.168.2.6:8080/",
      info: {},
    };
  },
  computed: {
    shopId() {
      return this.$route.params.shopId;
    },
  },
  methods: {
    ...mapActions({
      load: "seller/getSellerInfo",
      ifFocusShop: "seller/ifFocusShop",
    }),
    // 关注店铺
    follow() {
      this.ifFocusShop({
        shopId:this.$route.query.shopId,
        status:'1',//状态 1：关注 2：取消关注
    })
      console.log("关注店铺");
    },
    // 取消关注
    cancle() {
      console.log("取消关注");
      this.ifFocusShop({
        shopId:this.$route.query.shopId,
        status:'2',//状态 1：关注 2：取消关注
    })
    },

    onClickRight() {
      native(HANDLE_SHARE_SELLER, { shopId: this.shopId });
    },

    showQrCode() {
      this.show = true;
    },
    seller() {
      console.log("查看店铺详情");
    },
    to_verify() {
      // native(JUMP_VERIFY, { shopId: this.shopId });
      this.$router.push("/seller_verify/" + this.shopId);
    },
    toback() {
      this.$router.back();
    },
    jumpGoods() {
      this.$router.replace({
        path: "/goods",
        query: {
          shopId: this.shopId,
        },
      });
    },
  },
  created() {
    this.load(1).then((data) => {
      this.info = data;
    });
  },
  filters: {
    starsFilter(stars) {
      if (stars > 10000) {
        return parseInt(stars / 10000) + "万";
      } else {
        return stars;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
