/*
 * @Autor: oops.liu
 */
export default function(key, params = "", fn) {
  let isAndroid = navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Adr") > -1;
  if (window.webkit) {
    try {
      window.webkit.messageHandlers[key].postMessage(JSON.stringify(params));
    } catch {
      fn && fn();
    }
  } else if (isAndroid) {
    try {
      window.android[key](JSON.stringify(params));
    } catch {
      fn && fn();
    }
  } else {
    console.log("未检测到native：" + key + "，参数：", params);
  }
}
